import { getMenuInCurrentLanguage } from './getMenuInCurrentLanguage';

export const dataToBreadcrumbsFromNav = (language: string, url: string, additionalUrl?: string) => {
    const prepareAlias = (url: string): string => (url.startsWith('/') ? url : `/${url}`);
    const correctUrl = prepareAlias(url);
    const secondUrl = additionalUrl && prepareAlias(additionalUrl);
    const fullUrl = `/${language}${correctUrl}`;
    const fullSecondUrl = `/${language}${secondUrl}/`;
    const menu = getMenuInCurrentLanguage(language);
    const categoryWithSearchedPage = menu.filter(
        (category) =>
            category.accordionList.filter((subCategory) =>
                subCategory.accordionItems.find((item) => item.link === `${fullUrl}/`)
            ).length
    );

    const category = categoryWithSearchedPage.length > 0 && {
        name: categoryWithSearchedPage[0].name.name,
        url: categoryWithSearchedPage[0].name.link,
    };
    let categoryItem;
    let subCategory;
    let searchMainCategoryPage;
    let item;
    let items;
    let additionalItem;

    if (category) {
        menu.filter((category) => {
            category.accordionList.forEach((subCategory) => {
                items = subCategory.accordionItems.filter((item) => item.link === `${fullUrl}/`);
                if (items.length) {
                    item = items[0].name;
                }
            });
        });
        menu.forEach((category) => {
            subCategory = category.accordionList.filter((subCategory) =>
                subCategory.accordionItems.find((item) => item.link === `${fullUrl}/`)
            );
            if (subCategory.length) {
                categoryItem = subCategory[0]?.accordionTitle?.slice(0, -1);
            }
        });
    } else {
        searchMainCategoryPage = menu.filter((category) => category.name.link === `${fullUrl}/`);
        if (searchMainCategoryPage?.length > 0 && searchMainCategoryPage[0].name) {
            item = searchMainCategoryPage[0].name.name;
        }
    }

    if (additionalUrl) {
        menu.filter((category) => {
            category.accordionList.forEach((subCategory) => {
                items = subCategory.accordionItems.filter((item) => item.link === fullSecondUrl);
                if (items.length) {
                    additionalItem = { name: items[0].name, url: items[0].link };
                }
            });
        });
    }

    return { category, categoryItem, item, additionalItem };
};
