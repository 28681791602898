import React, { ReactNode } from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../../components/Layout/Layout';
import {
    Container,
    EventContent,
    EventWrapper,
    SummaryDate,
    SummaryText,
    SummaryWrapper,
    Title,
    EventImage,
} from './eventStyle';
import Header from '../../components/HeaderDynamicPages/Header';
import PrinterWrapper from '../../components/PrintWrapper/PrintWrapper';
import Alternate from '../../components/Seo/Alternate';
import { dataToBreadcrumbsFromNav } from '../../utils/dataToBreadCrumbsFromNav';
import { getNewsUrl } from '../../utils/getNewsUrl';
import { linkHelper } from '../../utils/linkHelper';
import { getBlogsUrl } from '../../utils/getBlogsUrl';
import { getPressInformationUrl } from '../../utils/getPressInformationUrl';
import { getDataForBreadCrumbsFromUrl } from '../../utils/getDataForBreadCrumbsFromUrl';
import { DownloadContainer } from '../page/pageStyle';
import DownloadSection from '../../components/DownloadSection/DownloadSection';

const Event = ({
    data: {
        drupal: { event: event, noIndex: noIndex },
    },
    pageContext: { languages: languages },
}) => {
    const { language } = useI18next();
    const newsUrl = getNewsUrl(language);
    const pressInformationUrl = getPressInformationUrl(language);
    const blogsUrl = getBlogsUrl(language);
    const { category, categoryItem } = dataToBreadcrumbsFromNav(language, newsUrl);
    let subCategory;
    if (category) {
        category.name = categoryItem;
    }
    const pathAliases = event?.url ? getDataForBreadCrumbsFromUrl(event.url) : [];
    if (event && event.parent && event.parent.title && event.parent.url) {
        subCategory = { name: event.parent.title, url: linkHelper(event.parent.url, language) };
    } else if (
        pathAliases.length &&
        (pathAliases[0] === pressInformationUrl || pathAliases[0] === newsUrl || pathAliases[0] === blogsUrl)
    ) {
        const { additionalItem } = dataToBreadcrumbsFromNav(language, newsUrl, pathAliases[0]);
        subCategory = additionalItem;
    } else if (event?.field_create_event_page) {
        const { additionalItem } = dataToBreadcrumbsFromNav(language, newsUrl, pressInformationUrl);
        subCategory = additionalItem;
    } else {
        const { additionalItem } = dataToBreadcrumbsFromNav(language, newsUrl, newsUrl);
        subCategory = additionalItem;
    }

    const displayFormattedDate = (dateStr: string, language: string): string => {
        const date = new Date(dateStr);
        const dayName = date.toLocaleDateString(language, { weekday: 'long' });
        const monthName = date.toLocaleDateString(language, { month: 'long' });
        const day = date.toLocaleDateString(language, { day: 'numeric' });
        const year = date.toLocaleDateString(language, { year: 'numeric' });
        return `${dayName}, ${monthName} ${day}. ${year}`;
    };

    const displayDownloadSection = (): ReactNode => {
        if (event.field_attachment) {
            return (
                <DownloadContainer isTitle={false}>
                    {event.field_attachment
                        .filter((item) => item?.description)
                        .map((item, index) => (
                            <DownloadSection key={index} item={item} />
                        ))}
                </DownloadContainer>
            );
        } else {
            return '';
        }
    };

    return (
        <Layout
            title={event?.metatag?.field_metatag_title}
            description={event?.metatag?.field_metatag_description}
            languageVersionsUrl={languages}
            noIndex={noIndex || event?.metatag?.field_metatag_no_index}
        >
            <Alternate languages={languages} />
            {event?.field_show_share_print ? (
                <PrinterWrapper>
                    <Header category={category} subCategory={subCategory} item={event?.title} />
                    <Container>
                        <EventWrapper>
                            <Title>{event?.title}</Title>
                            {event?.summary?.length > 0 && event?.field_event_date?.start_date !== null && (
                                <SummaryWrapper>
                                    <SummaryDate>
                                        {displayFormattedDate(event.field_event_date.start_date, language)}
                                    </SummaryDate>
                                    <SummaryText>{event.summary}</SummaryText>
                                </SummaryWrapper>
                            )}
                            <EventContent dangerouslySetInnerHTML={{ __html: event?.field_event_text }}></EventContent>
                        </EventWrapper>
                    </Container>
                    {displayDownloadSection()}
                </PrinterWrapper>
            ) : (
                <>
                    <Header category={category} subCategory={subCategory} item={event?.title} />
                    <Container>
                        <EventWrapper>
                            <Title>{event?.title}</Title>
                            {event?.summary?.length > 0 && event?.field_event_date?.start_date !== null && (
                                <SummaryWrapper>
                                    <SummaryDate>
                                        {displayFormattedDate(event.field_event_date.start_date, language)}
                                    </SummaryDate>
                                    <SummaryText>{event.summary}</SummaryText>
                                </SummaryWrapper>
                            )}
                            {event?.field_image?.length && (
                                <EventImage
                                    src={event.field_image[0]?.field_media_image}
                                    title={event.field_image[0]?.title ?? ''}
                                    alt={event.field_image[0]?.alt ?? ''}
                                />
                            )}
                            <EventContent dangerouslySetInnerHTML={{ __html: event?.field_event_text }}></EventContent>
                        </EventWrapper>
                    </Container>
                    {displayDownloadSection()}
                </>
            )}
        </Layout>
    );
};

export default Event;

export const query = graphql`
    query event($id: Int!, $language: String!) {
        drupal {
            event(id: $id, language: $language) {
                id
                title
                summary
                field_create_event_page
                field_event_text
                field_event_date {
                    start_date
                    end_date
                }
                field_attachment {
                    field_media_document
                    description
                    details {
                        filename
                        filemime
                        weight
                    }
                }
                parent {
                    title
                    url
                }
                metatag {
                    field_metatag_description
                    field_metatag_title
                    field_metatag_no_index
                }
                field_show_share_print
                url
                field_image {
                    title
                    alt
                    field_media_image
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
