import styled from 'styled-components';
import {
    blue,
    darkGreen,
    lightGreyButton,
    lightGraishButton,
    lightGray,
    greyBorder,
    paragraphMargin,
    containerWidth,
    paragraphWidthEm,
} from '../../styles/Variables';
import { deviceMax, deviceMin } from '../../styles/MediaQuery';
import { ContainerColumnCenter } from '../../styles/Common';

export const Container = styled(ContainerColumnCenter)`
    margin: 0 auto;
    margin-top: ${paragraphMargin};
`;

interface TitleInterface {
    readonly titleInHeader: boolean;
}

export const Title = styled.h1<TitleInterface>`
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
    color: ${blue};
    font-size: 2.5rem;
    margin-bottom: 0rem;
    line-height: 2.75rem;
    letter-spacing: 0.025em;
    word-break: break-word;

    @media ${deviceMin.tablet} {
        display: ${(props) => (props.titleInHeader ? 'none' : 'static')};
    }

    @media ${deviceMax.tablet} {
        font-size: 1.75rem;
    }
`;

interface TitleSectionWrapperProps {
    isWebform?: boolean;
}

export const TitleSectionWrapper = styled.div<TitleSectionWrapperProps>`
    ${(props) =>
        props.isWebform
            ? `
        width: ${containerWidth};

        @media ${deviceMax.tabletM} {
            width: calc(100% - 30px);
        }
    `
            : `
        width: ${paragraphWidthEm};

        @media ${deviceMax.tablet} {
            max-width: 90%;
            width: 90%;
        }
    `}
`;

interface BodyType {
    readonly isPageWithList?: boolean;
}

export const Body = styled.div<BodyType>`
    line-height: 1.5rem;
    font-weight: 400;
    padding: ${(props) => (props.isPageWithList ? '12px 0 6px 0' : '2px 0 6px 0')};
    font-family: Arial, Helvetica;

    ${(props) =>
        props.isPageWithList &&
        `
    * {
    padding: 0;
    margin: 0;
    }
    `};

    img {
        max-width: 100% !important;
    }
`;

interface DownloadContainerProps {
    readonly isTitle?: boolean;
}

export const DownloadContainer = styled.div<DownloadContainerProps>`
    max-width: ${containerWidth};
    margin: ${paragraphMargin} auto;
    ${(props) => !props.isTitle && `border-top: 1px solid ${greyBorder};`}

    @media ${deviceMax.tabletM} {
        padding: 0 15px;
    }
`;

export const PersonsLable = styled.p`
    font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
    font-size: 15px;
    max-width: ${containerWidth};
    margin: 4rem auto 0.5rem auto;
    text-align: left;
    text-transform: uppercase;

    @media ${deviceMax.tablet} {
        width: 96%;
    }
`;

export const InterestedInWrapper = styled.div`
    max-width: ${containerWidth};
    margin: 0 auto;

    h3 {
        color: ${blue};
        font-size: 1.5em;
    }

    @media ${deviceMax.laptop} {
        margin-left: 1em;
    }
`;

export const Paragraph = styled.div`
    font-size: 0.875em;
    line-height: 1.75em;

    * {
        font-family: Arial, Helvetica;
    }

    a.cmn_btn.txt_btn {
        display: inline-block;
        min-width: 150px;
        font-size: 0.875rem;
        line-height: 0.875rem;
        text-align: center;
        background: linear-gradient(${lightGreyButton}, ${lightGraishButton});
        color: ${darkGreen};
        border: 1px solid ${lightGray};
        padding: 14px 30px;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 10px;
        text-decoration: none;
        box-sizing: border-box;

        &:hover {
            background-color: ${lightGraishButton};
        }
    }

    a {
        text-decoration: none;
        color: ${darkGreen};
    }
`;

export const ContainerL = styled.div`
    margin-bottom: -20px;
    max-width: ${containerWidth};

    @media ${deviceMax.laptop} {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
`;

export const MultiCardSliderWrapper = styled.div`
    margin-top: ${paragraphMargin};

    @media ${deviceMax.laptop} {
        max-width: 624px;
    }

    @media ${deviceMax.tablet} {
        max-width: 302px;
    }
`;

export const TitleWrapper = styled.div`
    width: 100%;
`;
