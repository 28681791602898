import styled from 'styled-components';
import { deviceMax } from '../../styles/MediaQuery';
import { black, blue, darkGreen, greenDarker, greenLighter, greyBorder, greyText, white } from '../../styles/Variables';

export const Title = styled.h3`
    font-size: 0.94rem;
    text-transform: uppercase;
    line-height: 20px;
    margin: 0;
    letter-spacing: 0.025em;
    margin-top: 18px 0 11px 0;
    padding: 10px 0;
    border-bottom: 1px solid ${greyBorder};
    width: 100%;
    color: ${black};
`;

export const DownloadBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${greyBorder};

    @media ${deviceMax.tablet} {
        align-items: flex-start;
        padding-top: 1rem;
    }
`;

export const FileData = styled.div`
    margin-left: 1rem;
`;

export const FileTitle = styled.h2`
    color: ${blue};
    font-size: 1.5rem;
    font-weight: 700;
    align-self: flex-end;
    line-height: 1.5rem;

    @media ${deviceMax.tablet} {
        margin: 0;
    }
`;

export const FileSize = styled.p`
    color: ${greyText};
    font-size: 0.875rem;
    text-transform: uppercase;

    * {
        color: ${greyText};
        font-size: 0.875rem;
        text-transform: uppercase;
    }
`;

export const DownloadIcon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: linear-gradient(${greenLighter}, ${greenDarker});
    color: ${white};
    cursor: pointer;
    margin-right: 1rem;

    * {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${white};
        font-size: 1.5rem;
    }

    &:hover {
        background: ${darkGreen};
    }

    @media ${deviceMax.tablet} {
        margin-right: 0;
        margin: 0 1rem;
    }
`;
