import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
} from '../constants/languageCode';

export const getNewsUrl = (language: string): string => {
    switch (language) {
        case DE:
            return 'aktuelles';
        case DE_AT:
            return 'aktuelles';
        case EN_AU:
            return 'boge-updates';
        case EN_IN:
            return 'boge-updates';
        case EN_SG:
            return 'boge-updates';
        case EN_UK:
            return 'boge-updates';
        case EN_US:
            return 'boge-updates';
        case ES_ES:
            return 'noticias';
        case FR_FR:
            return 'nouveautes';
        case IT:
            return 'comunicati-stampa';
        case NL_NL:
            return 'actueel';
        case NL_BE:
            return 'actueel';
        case ZH_HANS:
            return 'boge-updates';
        case EN:
            return 'boge-updates';
        case FR:
            return 'nouveautes';
        case FR_BE:
            return 'nouveautes';
        case ES_US:
            return 'noticias';
        case ES:
            return 'noticias';
        default:
            return 'aktuelles';
    }
};
