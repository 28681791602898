import React from 'react';
import { DownloadBox, DownloadIcon, FileData, FileSize, FileTitle } from './DownloadSectionStyle';
import { RiDownloadLine } from 'react-icons/ri';
import { formatBytes } from '../../utils/formatBytes';

const DownloadSection = ({ item }) => (
    <DownloadBox>
        <FileData>
            <FileTitle>{item?.description}</FileTitle>
            <FileSize>
                <span>{item?.details?.filename.split('.').splice(1, 1)}, </span>
                {formatBytes(item?.details?.weight)}
            </FileSize>
        </FileData>
        <DownloadIcon>
            <a target="blank" href={item?.field_media_document}>
                <RiDownloadLine />
            </a>
        </DownloadIcon>
    </DownloadBox>
);

export default DownloadSection;
