import {
    DE,
    DE_AT,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES_ES,
    FR_FR,
    IT,
    NL_NL,
    NL_BE,
    ZH_HANS,
    EN,
    FR,
    FR_BE,
    ES_US,
    ES,
} from '../constants/languageCode';

export const getBlogsUrl = (language: string): string => {
    switch (language) {
        case DE:
            return 'blog-oesterreich';
        case DE_AT:
            return 'blog-oesterreich';
        case EN_AU:
        case EN_IN:
        case EN_SG:
        case EN_UK:
        case EN_US:
        case ES_ES:
        case FR_FR:
        case IT:
        case NL_NL:
        case NL_BE:
        case ZH_HANS:
        case EN:
        case FR:
        case FR_BE:
        case ES_US:
        case ES:
        default:
            return 'blog-oesterreich';
    }
};
